.location {
    width: 100%;
    text-align: left;
    font-size: small;
}

.location-select {
    width: 200px;
    display: inline-flex;
    font-size: .75rem;
}

.person-face {
    color: #b0cfcf;
    font-size: 2.0rem;
}

.margin-left-10 {
    margin-left: 10px;   
}
