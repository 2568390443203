body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.content-medium {
  max-width: 720px;
  margin: 40px auto;
  padding: 20px;
}

.content-large {
  max-width: 900px;
  margin: 10px auto;
  padding: 10px;
}

.content-xlarge {
  max-width: 1024px;
  margin: 5px auto;
  padding: 5px;
}

.content-min-height-720 {
  min-height: 720px;
  margin-bottom: 100px;
}

.btn {
  padding: 13px 28px 14px;
  margin: 2vh;
  font-weight: 700;
  text-transform: capitalize;
  font-family: sans-serif;
  border-radius: 10px;
  line-height: 1.333em;
  color: #fff;
  text-decoration: none;
}

.btn-primary {
  background: #7e9c9f;
  background-color: #7e9c9f;
  border-color: #7e9c9f;
  background-size: 200% 100%;
  background-position: left bottom;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  cursor: pointer;
}

.btn-primary:hover {
  color: #fff;
  background-color: #6c9194; /* new color goes her */
  border-color: #7e9c9f;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #6c9194; /* new color goes her */
  border-color: #7e9c9f;
}

.btn-tablet-large {
  height: 8rem;
  width: 8rem;
}

.large-font-icon {
  font-size: 3.0rem;
  margin-bottom: 10px;
}

.large-font-text {
  font-size: 0.8rem;
}

.large-font-text-therapist {
  font-size: 0.9rem;
}



.me-Toastify__toast-theme--light {
  background: #4c6669;
  color: #fff;
}

.mi-Toastify__toast-container {
  width: 500px;
  margin-top: 200px;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.remove-margin-40 {
  margin-top: -40px;
  margin-right: -20px;
}

.default-control {
  width: 50vh;
  height: 40px;
}

.form-floating>label {
  padding: .5rem .75rem;
}

.form-floating>.form-control {
  line-height: 1.25;
  height: calc(2.5rem + 2px);
}

#titus-fieldset {
    border-color: #000;
    border-style: solid;
    border-width: 2px;
}

fieldset > legend {
  background-color: #6c9194 !important;
  color: #fff !important;
  font-size: 1rem !important;
  padding-left: 8px !important;
  margin-left: 4px !important;
  margin-bottom: .0rem !important;
  width: 80% !important;
  float: none !important;
}

.output {
  font: 1rem sans-serif;
}

.margin-1-rem {
  margin: .5rem;
}

.modal {
  background: rgba(0, 0, 0, 0.6);
}
.modal-container {
  position: absolute;
  width: 60%;
  background: #ffffff;
  left: 25%;
  top: 30%;
  padding: 20px;
}

.show {
  display: block;
  transition: 800ms;
  opacity: 1;
}

.show.active {
  transform: translateY(100px) scale(0) rotateY(180deg);
  opacity: 0;
}

