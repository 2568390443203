.content-login-bg {

    /* Full height */
    height: 100vh;
    width: 100vw;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content-login {
    width: 28em;
    position: absolute;
    right: 20%;
    top: 32%;
}

.content-plan {
    width: 28em;
    position: absolute;
    right: 20%;
    top: 22%;
}

.content-org {
    width: 28em;
    position: absolute;
    right: 20%;
    top: 15%;
}

.content-signup {
    width: 28em;
    position: absolute;
    right: 20%;
    top: 10%;
}

.login-button {
    width: 320px;
    height: 35px;
    font-size: medium;
    padding: 0;
}

.plan-button {
    width: 200px;
    height: 35px;
    font-size: medium;
    padding: 6px !important;
}

.signup-label {

    font-size: .8rem;
}

.margin-2 {
    margin: 2px;

}


.price-table-container {
    display: flex;
    box-shadow: 0 2px 5px 0 rgba(60, 66, 87, .08), 0 1px 1px 0 rgba(0, 0, 0, .12);
}

.price-table-container section {
    border-radius: 6px;
    padding: 32px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 352px;
    background-color: #ffffff;
}

.price-table-container section:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 1px solid #efefef;
}

.price-table-container section:last-of-type {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.price-table-container button {
    background-color: #F0F0F0;
    color: #000000;
    border-radius: 64px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.price-table-container button:hover {
    background-color: #d6d6d6;
    filter: none;
}

.price-table-container .name {
    color: #7F7F7F;
    font-size: large;
    margin-top: 8px;
    text-align: center;
}

.price-table-container .price {
    color: #000000;
    font-size: x-large;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: center;
}

.price-table-container .duration {
    color: #969696;
    font-size: smaller;
    text-align: left;
}

.price-table-container .location {
    text-align: center;
    margin-top: 10px;
}

.margin-top-20 {

    margin-top: 20px;
}


input[type='number'] {
    width: 100px;
}

.text-align-left {
    text-align: left;
}

/* input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

.line {
    margin-top: 0px;
    color: #7e9c9f;
}