.App {
    text-align: left;
    line-height: 1.46em;
    font-weight: 400;
    font-family: sans-serif;
}

.Org-logo {
    height: 6vh;
    pointer-events: none;
    margin: 1vh;
}

.App-logo {
    height: 8vh;
    pointer-events: none;
    margin: 0.5vh;
}

.App-header {
    background-color: #fff;
    min-height: 5vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    align-items: left;
    justify-content: left;
}

.App-footer {
    margin-top: 0rem;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

li {
    padding: 10px 0;
}

.navbar {
    
    align-items: center;
    border-bottom: 2px solid #f2f2f2;
    
}

.navbar h3 {
    color: #fff;
    margin-left: 1vh;
}

.navbar .links {
    margin-left: auto;
}

.navbar a {
    margin: 2vh;
    text-decoration: none;
    padding: 6px;
    color: #7e9c9f;
    background-color: '#aeaeae';
    border-radius: '8px';
}

.navbar a:hover {
    color: #eee;
}

.loginButton {
    color: #7e9c9f;
    margin: 0%;
}

.links {
    height: 0;
    overflow: hidden;
    list-style-type: none;
    font-weight: bold;
}

.show-nav {
    height: 120px;
}

/* Media screen size */
@media screen and (min-width: 768px) {
    button {
        display: none;
    }

    .links {
        height: auto;
        display: flex;
    }

    li {
        padding: 0 15px;
    }
}

.content-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 78vh;
}

.div-full-right {
    width:100%; 
    text-align:right
}

.customToastContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    min-height: 40vh;
}

.Toastify__toast-theme--light {
    background: #f2f5f5 !important;
    font-family: sans-serif !important;
    color: #000 !important;
}

.Toastify__toast {
    
    min-width: 350px   !important ;
    max-width: 400px   !important ;
    min-height: 250px   !important ;
    max-height: 400px   !important ;
}

.font-15pt-center {
    font-size: 15pt;
    text-align: center;
}

.font-15pt-left {
    font-size: 15pt;
    text-align: left;
}

input:disabled,
input[disabled]{
  background-color: #cccccc;
  color: #666666;
}

.btn-primary.disabled, .btn-primary:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.action-column {
    width: 60px;
    text-align: center;
    vertical-align: middle;
}

.action-column-icon {
    margin-left: 8px;
    margin-right: 8px;
    
}